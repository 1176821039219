<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm && !showFormLegado"
        :columns="columns"
        :rows="listaAtendimentos"
        sort-name="dataAtendimento"
        title-table="Atendimentos"
        module="atendimentoModule"
        @add="addNew"
        @edit="edit"
      >
        <slot slot="buttons">
          <b-button
            variant="success"
            class="ml-1"
            data-cy="inserirLegado"
            @click="inserirLegado"
          >
            Inserir Atendimento Legado
          </b-button>
        </slot>
      </TableComponents>
      <AttendanceForm v-if="showForm" @cancelar="cancelar" />
      <div v-if="showFormLegado">
        <AtendimentoLegadoForm @cancelarLegado="cancelarLegado" />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol, BButton } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import AttendanceForm from '../form/AttendanceForm.vue'
import AtendimentoLegadoForm from '../../atendimentoLegado/AtendimentoLegadoForm.vue'

export default {
  name: 'Atendimento',
  components: {
    BRow,
    BCol,
    TableComponents,
    AttendanceForm,
    BButton,
    AtendimentoLegadoForm
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboFormaPagamento: (state) => state.comboFormaPagamento,
      comboTipoPagamento: (state) => state.comboTipoPagamento
    }),
    ...mapState('atendimentoModule', {
      listaAtendimentos: (state) => state.listaAtendimentos
    })
  },
  data() {
    return {
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Nº Atendimento',
          field: 'numeroAtendimento'
        },
        {
          label: 'Data Atendimento',
          field: 'dataAtendimento',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Hora',
          field: 'horaAgenda'
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: true
        },
        {
          label: 'Nome Solicitante',
          field: 'registro.nome',
          sortable: true
        },
        {
          label: 'Situação',
          field: 'situacao.descricao',
          sortable: false,
          tdClass: this.tdClassFunc
        },

        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      message: 'Atualizando a cada 3 minutos',
      intervalId: null,
      showFormLegado: false
    }
  },
  mounted() {
    this.startAutoUpdate()
  },
  beforeRouteLeave(to, from, next) {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    next()
  },
  methods: {
    inserirLegado() {
      this.showFormLegado = true
    },
    startAutoUpdate() {
      this.intervalId = setInterval(() => {
        this.loadTable()
      }, 300000)
      // 300000 Inicia o setInterval para atualizar a cada 3 minutos (180000 ms)
    },
    stopAutoUpdate() {
      // Limpa o intervalo quando o componente é desmontado
      clearInterval(this.intervalId)
    },
    async addNew() {
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `editAtendimento/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    },
    cancelarLegado() {
      this.showFormLegado = false
    },
    loadTable() {
      this.$store.dispatch('atendimentoModule/loadTable', this.pageConfig)
    },
    tdClassFunc(row) {
      if (row.situacao && row.situacao.descricao) {
        const status = row.situacao.descricao.toLowerCase()
        switch (status) {
          case 'aguardando':
            return 'text-primary'
          case 'em atendimento':
            return 'text-info'
          case 'finalizado':
            return 'text-success'
          case 'cancelado':
            return 'text-danger'
          case 'pendente':
            return 'text-warning'
          default:
            return ''
        }
      }
    }
  }
}
</script>
