var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Tipo de Atendimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Atendimento","label-for":"v-tipoAtendimento"}},[_c('v-select',{attrs:{"reduce":function (comboTiposAtendimento) { return comboTiposAtendimento.id; },"label":"nome","options":_vm.comboTiposAtendimento,"state":errors.length > 0 ? false : null,"data-cy":"tipo"},on:{"input":_vm.loadEspecialidades},model:{value:(_vm.form.tipoAtendimento),callback:function ($$v) {_vm.$set(_vm.form, "tipoAtendimento", $$v)},expression:"form.tipoAtendimento"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-tipoAtendimento"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Especialidades","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Especialidades","label-for":"v-especialidade"}},[_c('v-select',{attrs:{"reduce":function (comboTiposAtendimento) { return comboTiposAtendimento.id; },"label":"descricao","options":_vm.comboEspecialidades,"state":errors.length > 0 ? false : null,"data-cy":"especialidade"},on:{"input":_vm.loadProfissional},model:{value:(_vm.form.especialidade),callback:function ($$v) {_vm.$set(_vm.form, "especialidade", $$v)},expression:"form.especialidade"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-especialidade"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Profissional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Profissional","label-for":"v-profissional"}},[_c('v-select',{attrs:{"label":"nome","options":_vm.comboProfissionals,"state":errors.length > 0 ? false : null,"data-cy":"profissional"},model:{value:(_vm.form.profissional),callback:function ($$v) {_vm.$set(_vm.form, "profissional", $$v)},expression:"form.profissional"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-profissional"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"solicitante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Solicitante","label-for":"v-solicitante"}},[_c('v-select',{attrs:{"label":"nome","filterable":false,"options":_vm.comboRegistros,"selected":_vm.form.registro},on:{"search":_vm.onSearchPaciente},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.nome)+" ")])]}}],null,true),model:{value:(_vm.form.registro),callback:function ($$v) {_vm.$set(_vm.form, "registro", $$v)},expression:"form.registro"}},[_c('template',{slot:"no-options"},[_vm._v(" Pesquisa registro por nome, cpf ou matrícula ")])],2),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-solicitante"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Unidade Atendimento","label-for":"v-unidadeAtendimento"}},[_c('v-select',{attrs:{"reduce":function (comboUnidadeAtendimento) { return comboUnidadeAtendimento.id; },"label":"nome","options":_vm.comboUnidadeAtendimento},model:{value:(_vm.form.unidadeAtendimento),callback:function ($$v) {_vm.$set(_vm.form, "unidadeAtendimento", $$v)},expression:"form.unidadeAtendimento"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Observações","label-for":"v-observacao"}},[_c('b-form-textarea',{attrs:{"id":"v-observacao","row":"2","placeholder":"Digite aqui suas observações"},model:{value:(_vm.form.observacao),callback:function ($$v) {_vm.$set(_vm.form, "observacao", $$v)},expression:"form.observacao"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Data Atendimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data Atendimento","label-for":"v-dataAtendimento"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d'
                }},model:{value:(_vm.form.dataAtendimento),callback:function ($$v) {_vm.$set(_vm.form, "dataAtendimento", $$v)},expression:"form.dataAtendimento"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-dataAtendimento-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Hora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Hora","label-for":"v-hora"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                    altInput: true,
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'H:i',
                    time_24hr: true
                  }},model:{value:(_vm.form.horaInicial),callback:function ($$v) {_vm.$set(_vm.form, "horaInicial", $$v)},expression:"form.horaInicial"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-hora-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('ButtonsFormVue',{attrs:{"disable-form":false,"loading-button":_vm.loading,"permission-insert":_vm.verificarPermissao('ROLE_ATEND_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR')},on:{"save":_vm.save,"edit":function () {},"cancel":_vm.beforeCancelar}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }